// src/components/Help/Help.js

import React from 'react';
import Header from './Header';
import styles from './Help.module.css';

function Help() {
  return (
    <div className={styles.helpContainer}>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <section className={styles.mainContent}>
        <div className={styles.textContentTitle}>
          <h1 className={styles.title}>Help</h1>
          <p className={styles.subtitle}>
            We're here to assist you with any questions or issues you may have.
          </p>
        </div>
        <div className={styles.content}>
          <p className={styles.paragraph}>
            If you need assistance, please contact our support team at{' '}
            <a href="mailto:support@flipptickets.com" className={styles.link}>
              support@flipptickets.com
            </a>{' '}
            or call us at <a href="tel:+11234567890" className={styles.link}>(123) 456-7890</a>.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Help;
