// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import { GoogleAuthProvider } from './GoogleAuthContext'; // Use your existing GoogleAuthContext.js
import Header from './Header';
import Homepage from './Homepage';
import YourTransactions from './YourTransactions';
import AboutUs from './AboutUs';
import Help from './Help';
import MakeAListing from './MakeAListing';
import RequestATicket from './RequestATicket';
import SubmitListing from './SubmitListing';
import EditListing from './EditListing';
import LinkScreen from './LinkScreen';
import AcceptRequest from './AcceptRequest';
import FAQ from './FAQ'; // New component
import PrivacyPolicy from './PrivacyPolicy'; // New component
import TermsAndConditions from './TermsAndConditions'; // New component
import ProtectedRoute from './ProtectedRoute';
import Footer from './Footer'; // Import the Footer component
import './App.css'; // Import the CSS file

function App() {
  return (
    <Router>
      <GoogleAuthProvider>
        <Header />
        <ScrollToTop />
        <div className="app-container"> {/* Apply the CSS class here */}
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route 
              path="/your-transactions" 
              element={
                <ProtectedRoute>
                  <YourTransactions />
                </ProtectedRoute>
              } 
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<FAQ />} /> {/* New Route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Privacy Policy Route */}
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> {/* New Route */}
            <Route path="/help" element={<Help />} />
            <Route path="/make-a-listing" element={<MakeAListing />} />
            <Route
              path="/request-a-ticket"
              element={
                <ProtectedRoute>
                  <RequestATicket />
                </ProtectedRoute>
              }
            />
            <Route path="/submit-listing" element={<SubmitListing />} />
            <Route path="/edit-listing" element={<EditListing />} />
            <Route path="/linkscreen" element={<LinkScreen />} />
            <Route path="/acceptrequest" element={<AcceptRequest />} />
            {/* Add other routes as needed */}
          </Routes>
        </div>
        <Footer /> {/* Add the Footer component here */}
      </GoogleAuthProvider>
    </Router>
  );
}

export default App;
