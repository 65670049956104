// src/utils/networks.js

export const networks = {
  baseMainnet: {
    chainId: '0x2105', // 8453 in hex
    chainName: 'Base Mainnet',
    rpcUrls: ['https://mainnet.base.org'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://basescan.org'],
  },
  baseSepolia: {
    chainId: '0x14A34', // 84532 in hex
    chainName: 'Base Sepolia Testnet',
    rpcUrls: ['https://sepolia.base.org'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://sepolia-explorer.base.org'],
  },
  // Add more networks as needed
};