// PrivacyPolicy.jsx

import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated:</strong>  11.23.2024</p>

      <p>
        Welcome to Flipp Tickets (“we”, “our”, “us”). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, store, and protect your information when you use our application.
      </p>

      <h2>1. Introduction</h2>
      <p>
        Flipp Tickets is a peer-to-peer (P2P) payment processing system that allows users to exchange tickets to live events securely through the blockchain. Our platform is deployed on Base, providing a reliable and secure environment for your transactions. To enhance your experience, we integrate with Google services using OAuth2 for authentication and authorization, specifically to access your email information with read-only permissions.
      </p>
      <p>
        By using our app, you agree to the collection and use of information in accordance with this Privacy Policy.
      </p>

      <h2>2. Information We Collect</h2>

      <h3>2.1. Access Tokens and Refresh Tokens</h3>
      <ul>
        <li><strong>Access Tokens:</strong> These tokens allow us to access your Gmail account with read-only permissions. The access tokens are necessary for the app to function as intended.</li>
        <li><strong>Refresh Tokens:</strong> These tokens enable us to obtain new access tokens when the current ones expire, ensuring continuous access without requiring you to re-authenticate.</li>
      </ul>

      <h3>2.2. User Information</h3>
      <ul>
        <li><strong>Name:</strong> We collect your name as provided by your Google account to personalize your experience within the app.</li>
        <li><strong>Google Profile Pictrue:</strong> We collect your profile picture as provided by your Google account to personalize your experience within the app.</li>
        <li><strong>Email Address:</strong> We collect your email address associated with your Google account to verify that you received the proper verification email from Ticketmaster, ensuring that you transferred the correct ticket.</li>
        <li><strong>Listed Tickets:</strong>  The tickets that are listed in our platform are stored to enhance the experience of users in order to allow for the editing/removal of such listings.</li>
        <li><strong>Wallet Addresses:</strong> The wallet addresses are stored in order to populate the smart contract with the proper information and facilitate the exchange of funds between users upon the completion of a transaction.</li>      
      </ul>

      <h3>2.3. Additional Information</h3>
      <ul>
        <li><strong>IP Address:</strong> When you use our platform, we automatically receive your computer's Internet Protocol (IP) address. This information helps us understand your browser and operating system, enhancing the security and functionality of our services.</li>
      </ul>

      <h3>2.4. No Additional Personal Information</h3>
      <p>
        We do not collect, store, or process any personal information beyond the access tokens, refresh tokens, name, profile picture, email address, wallet addresses, listed tickets, and IP address necessary for the app's functionality.
      </p>

      <h2>3. How We Use Your Information</h2>

      <h3>3.1. Accessing Your Emails</h3>
      <ul>
        <li><strong>Read-Only Access:</strong> We use the access tokens to read your emails solely to verify that you received the proper verification email from Ticketmaster, ensuring that you transferred the correct ticket. We do not store, modify, delete, or send any emails on your behalf.</li>
      </ul>

      <h3>3.2. User Information Usage</h3>
      <ul>
        <li><strong>Personalization:</strong> Your name is used to personalize your experience within the app.</li>
        <li><strong>Verification:</strong> Your email address is used to confirm the receipt of verification emails from Ticketmaster related to your ticket transfers.</li>
      </ul>

      <h3>3.3. IP Address Usage</h3>
      <ul>
        <li><strong>Security and Analytics:</strong> Your IP address helps us monitor and protect our platform from unauthorized access and abuse. It also assists us in understanding user behavior to improve our services.</li>
      </ul>

      <h3>3.4. Token Management</h3>
      <ul>
        <li><strong>Storage:</strong> Access tokens, refresh tokens, your name, email address, wallet addresses, listed tickets, and IP address are stored securely on our servers to maintain the app’s functionality.</li>
        <li><strong>Usage:</strong> These tokens and user information are used exclusively to access your email data and verify ticket transfers as described and are not used for any other purposes.</li>
      </ul>

      <h3>3.5. Email Marketing</h3>
      <p>
        <strong>With Your Consent:</strong> With your permission, we may send you emails about our platform, new products, and other updates. You can opt-in to receive these communications during the sign-up process or within your account settings.
      </p>

      <h2>4. Consent</h2>

      <h3>4.1. How Do You Get My Consent?</h3>
      <p>
        When you provide us with personal information to complete a transaction, verify your email, or use our services, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent or provide you with an opportunity to say no.
      </p>

      <h3>4.2. How Do I Withdraw My Consent?</h3>
      <p>
        If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use, or disclosure of your information at any time by contacting us at <a href="mailto:admin@flipptickets.com">admin@flipptickets.com</a>.
      </p>

      <h2>5. Disclosure</h2>
      <p>
        We may disclose your personal information if we are required by law to do so or if you violate our Terms and Conditions.
      </p>
      <p>
        No mobile information will be shared with third parties or affiliates for marketing or promotional purposes. All other categories of information we collect exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
      </p>

      <h2>6. Third-Party Services</h2>
      <p>
        In general, the third-party providers used by us will only collect, use, and disclose your information to the extent necessary to allow them to perform the services they provide to us.
      </p>
      <p>
        For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
      </p>

      <h3>6.1. Links</h3>
      <p>
        When you click on links on our platform, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
      </p>

      <h2>7. Security</h2>

      <h3>7.1. Secure Storage</h3>
      <ul>
        <li><strong>Encryption:</strong> All tokens and user information are encrypted both in transit and at rest using industry-standard encryption protocols to prevent unauthorized access.</li>
        <li><strong>Access Controls:</strong> Only authorized personnel have access to the tokens and user information, and access is granted based on the principle of least privilege.</li>
      </ul>

      <h3>7.2. Data Retention</h3>
      <ul>
        <li><strong>Retention Period:</strong> Tokens and user information are retained only for as long as necessary to provide the app’s services. If you choose to disconnect your Google account or delete your app account, all associated tokens and user information will be securely deleted.</li>
      </ul>

      <h3>7.3. Security Measures</h3>
      <p>
        We implement robust security measures, including firewalls, intrusion detection systems, and regular security audits, to protect your tokens and user information from unauthorized access, alteration, or disclosure.
      </p>

      <h3>7.4. Incident Response Plan</h3>
      <ul>
        <li><strong>Preparedness:</strong> We have developed a plan to respond to data breaches or security incidents promptly.</li>
        <li><strong>Notification:</strong> In the event of a breach, we will notify affected users and relevant authorities as required by law.</li>
      </ul>

      <h2>8. Age of Consent</h2>
      <p>
        By using this platform, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this platform.
      </p>

      <h2>9. User Rights</h2>

      <h3>9.1. Access and Control</h3>
      <ul>
        <li><strong>Access:</strong> You have the right to access the information we hold about you.</li>
        <li><strong>Correction:</strong> You can request corrections to any inaccuracies in your information.</li>
        <li><strong>Deletion:</strong> You can request the deletion of your tokens and any associated data, including your name and email address, by disconnecting your Google account from our app.</li>
      </ul>

      <h3>9.2. Opt-Out</h3>
      <p>
        <strong>Disconnecting Google Account:</strong> You can revoke our access to your Gmail account at any time through your Google account settings.
      </p>

      <h3>9.3. Email Marketing Preferences</h3>
      <p>
        <strong>Opt-Out of Marketing Emails:</strong> You can withdraw your consent to receive marketing emails at any time by following the unsubscribe instructions included in each email or by contacting us at <a href="mailto:admin@flipptickets.com">admin@flipptickets.com</a>.
      </p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        We reserve the right to modify this Privacy Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. The updated version will always be available on our platform with an updated “Last Updated” date.
      </p>

      <h2>11. Contact Information</h2>
      <p>
        For any questions or concerns about these terms, please contact us at:
      </p>
      <ul>
        <li><strong>Email:</strong> <a href="mailto:admin@flipptickets.com">admin@flipptickets.com</a></li>
        <li><strong>Address:</strong> 517 w 113th St, New York, NY</li>
        <li><strong>Phone:</strong> 347-3270-324</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
