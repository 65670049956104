// src/components/AboutUs/AboutUs.js

import React from 'react';
import Header from './Header';
import AccordionItem from './AccordionItem';
import styles from './AboutUs.module.css';

const accordionData = [
  {
    title: 'Our Mission',
    content:
      'At Flipp Tickets, our mission is to provide a secure and user-friendly platform for transferring tickets safely to your peers.',
  },
  {
    title: 'How It Works',
    content:
      'Simply list your tickets, connect with peers, and securely transfer ownership with ease. Our platform ensures all transactions are safe and reliable.',
  },
  {
    title: 'Why Choose Us',
    content:
      'We prioritize security, user experience, and customer support to ensure you have the best ticketing experience possible.',
  },
  {
    title: 'Our Team',
    content: `<strong>Hasan Alqassab:</strong><br />
CS @ Columbia<br />
Previously Blockchain Engineering @BlockApps, SWE @ Steinghart Lab, SWE @ VirtuThinko<br />
Bahraini Crown Prince Scholar<br />
AI TA @ Columbia<br /><br />

<strong>Jacobo Guzowski:</strong><br />
Applied Math @ Columbia<br />
Previously Blockchain Engineering @BlockApps<br />
Previously managed $100k DOE funding for own project @ Columbia Plasma Physics Lab<br /><br />

<strong>Alexander Schwartz:</strong><br />
CS @ Columbia<br />
Previously SWE @ Anchorage Digital, SWE @ Bourtsalas Lab<br />
AI, Data Structures TA @ Columbia`,
  },
  {
    title: 'Contact Us',
    content:
      'Have questions? Reach out to our support team anytime, and we’ll be happy to assist you with your ticketing needs.',
  },
];

function AboutUs() {
  return (
    <div className={styles.aboutUsContainer}>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <section className={styles.individualListing}>
        <div className={styles.textContentTitle}>
          <h1 className={styles.title}>About Us</h1>
          <p className={styles.subtitle}>
            Learn more about our mission, values, and team.
          </p>
        </div>
        <div className={styles.accordion}>
          {accordionData.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </section>
    </div>
  );
}

export default AboutUs;