// src/components/FAQ/FAQ.jsx

import React from 'react';
import AccordionItem from './AccordionItem'; // Ensure AccordionItem is correctly imported
import styles from './FAQ.module.css'; // Import the CSS module for styling
import { Link } from 'react-router-dom'; // For internal links

const accordionData = [
  {
    title: 'Why should I trust you?',
    content: (
      <>
        At <strong>Flipp Tickets</strong>, trust and security are our top priorities. Our platform utilizes blockchain technology to facilitate secure and transparent peer-to-peer (P2P) transactions. We guarantee a maximum transaction window of <strong>24 hours</strong> between payment and receiving your ticket, ensuring timely and reliable transfers. Additionally, we offer multiple payment options, including <strong>Base using USDC</strong> and <strong>Stripe</strong>, both of which are trusted and secure payment processors. Our dedicated customer support team is always available to assist you, and we implement stringent measures to protect your personal and financial information.
      </>
    ),
  },
  {
    title: 'Are there fees?',
    content: (
      <>
        <strong>Flipp Tickets</strong> strives to provide a cost-effective platform for buying and selling tickets. We charge a minimal service fee on each transaction to maintain and improve our platform. These fees are transparently displayed during the checkout process, so you know exactly what you’re paying for. There are no hidden charges, and our fee structure is designed to be competitive within the industry. For detailed information on our fee structure, please visit our <Link to="/fee-policy"><strong>Fee Policy</strong></Link> page.
      </>
    ),
  },
  {
    title: 'How are payments processed?',
    content: (
      <>
        Payments on <strong>Flipp Tickets</strong> can be processed through two secure methods:
        <ol>
          <li>
            <strong>Base using USDC:</strong> This method leverages blockchain technology, allowing you to make and receive payments using USD Coin (USDC), a stablecoin pegged to the US Dollar. Transactions are secure, transparent, and immutable.
          </li>
          <li>
            <strong>Stripe:</strong> For those who prefer traditional payment methods, we integrate with <strong>Stripe</strong>, a trusted third-party payment processor. Stripe ensures that your credit card information is handled securely and complies with industry standards.
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'I sold my ticket, so when do I get paid?',
    content: (
      <>
        Once a ticket is sold on <strong>Flipp Tickets</strong>, the payment process is initiated immediately. We guarantee that you will receive your payment within a <strong>maximum of 24 hours</strong> from the time the buyer completes the payment. This swift transaction window ensures that you can promptly reinvest or use your funds as needed.
      </>
    ),
  },
  {
    title: 'How do I get paid?',
    content: (
      <>
        Payments on <strong>Flipp Tickets</strong> are processed through the method you selected during the sale:
        <ul>
          <li>
            <strong>Base using USDC:</strong> Your USDC tokens will be transferred directly to your connected wallet on the Base blockchain.
          </li>
          <li>
            <strong>Stripe:</strong> Payments processed via <strong>Stripe</strong> will be deposited into your linked bank account or card, depending on your Stripe account settings.
          </li>
        </ul>
        To ensure a smooth payment process, please make sure that your payment information is accurate and up-to-date in your account settings.
      </>
    ),
  },
];

function FAQ() {
  return (
    <div className={styles.faqContainer}>
      {/* Main Content */}
      <section className={styles.faqSection}>
        <div className={styles.textContentTitle}>
          <h1 className={styles.title}>Frequently Asked Questions</h1>
          <p className={styles.subtitle}>
            Find answers to common questions about using Flipp Tickets.
          </p>
        </div>
        <div className={styles.accordion}>
          {accordionData.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </section>
    </div>
  );
}

export default FAQ;
