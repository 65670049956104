import React, { useState, useEffect, createContext, useContext } from 'react';

const BACKEND_IP = process.env.REACT_APP_BACKEND_IP;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Create the Context
const GoogleAuthContext = createContext();

// Custom Hook to consume the GoogleAuthContext
export function useGoogleAuth() {
  const context = useContext(GoogleAuthContext);
  if (!context) {
    throw new Error('useGoogleAuth must be used within a GoogleAuthProvider');
  }
  return context;
}

// Provider Component
export function GoogleAuthProvider({ children }) {
  // State variables to manage authentication status, user profile, and user email
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [userProfile, setUserProfile] = useState(
    localStorage.getItem('userProfile') ? JSON.parse(localStorage.getItem('userProfile')) : null
  );
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');

  useEffect(() => {
    // Load the Google API script if it's not already loaded
    if (!window.google) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    } else {
      initializeGoogleSignIn();
    }
  }, []);

  /**
   * Initializes the Google Sign-In process by setting up the code client.
   */
  function initializeGoogleSignIn() {
    const { google } = window;
    if (!google) {
      console.error('Google API not loaded.');
      return;
    }

    // Initialize the OAuth 2.0 code client
    const codeClient = google.accounts.oauth2.initCodeClient({
      client_id: GOOGLE_CLIENT_ID,
      scope: 'openid email profile',
      ux_mode: 'popup',
      redirect_uri: 'postmessage',
      access_type: 'offline',
      prompt: 'consent',
      callback: (response) => {
        const code = response.code;
        console.log("debug a:", code);
        saveGmailCode(code);
      },
    });

    // Store the code client globally for later use
    window.codeClient = codeClient;
  }

  /**
   * Handles the sign-in button click by requesting an authorization code.
   */
  function handleSignInClick() {
    if (window.codeClient) {
      window.codeClient.requestCode();
    } else {
      console.error('Google Code Client not initialized.');
    }
  }

  /**
   * Handles user logout by clearing state and local storage.
   */
  function handleLogout() {
    setIsLoggedIn(false);
    setUserProfile(null);
    setUserEmail('');

    // Clear local storage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('userEmail');

    initializeGoogleSignIn();
  }

  /**
   * Sends the authorization code to the backend to exchange for tokens and retrieve user data.
   * @param {string} code - The authorization code received from Google.
   */
  function saveGmailCode(code) {
    fetch(`${BACKEND_IP}/save-gmail-code`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((errorData) => {
            throw new Error(errorData.error || 'An error occurred');
          });
        }
      })
      .then((data) => {
        // Update authentication state
        setIsLoggedIn(true);
        setUserProfile(data);
        setUserEmail(data.user_email);

        // Save login state to local storage
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userProfile', JSON.stringify(data)); // Convert userProfile to JSON string
        localStorage.setItem('userEmail', data.user_email);
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
  }

  return (
    <GoogleAuthContext.Provider
      value={{ isLoggedIn, userProfile, userEmail, handleSignInClick, handleLogout }}
    >
      {children}
    </GoogleAuthContext.Provider>
  );
}
