// AcceptRequest.js

import React, { useState, useEffect } from 'react';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import { useGoogleAuth } from './GoogleAuthContext';
import { FaCheckCircle } from 'react-icons/fa';
import styles from './AcceptRequest.module.css';
import { switchNetwork } from './utils/switchNetwork';
import { Contract } from 'ethers';

const BACKEND_IP = process.env.REACT_APP_BACKEND_IP;
const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function AcceptRequest() {
  const location = useLocation();

  const {
    isLoggedIn,
    userEmail,
    userProfile,
    handleSignInClick,
    handleLogout,
  } = useGoogleAuth();

  const [sellerEmail, setSellerEmail] = useState('');

  useEffect(() => {
    if (userEmail) {
      setSellerEmail(userEmail);
      console.log("seller email is set to: ", sellerEmail);
    }
  }, [userEmail]);

  const searchParams = new URLSearchParams(location.search);
  const contractAddress =
    (location.state && location.state.contractAddress) ||
    searchParams.get('contractAddress');

  const [listingData, setListingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTransactionConfirmed, setIsTransactionConfirmed] = useState(false);
  const [isFundsReleased, setIsFundsReleased] = useState(false);

  const [copied, setCopied] = useState(false);

  // State variables for popup message and loading status
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);

  // State variable to track pending action
  const [pendingAction, setPendingAction] = useState('');

  const handleCopyEmail = () => {
    const shareEmail = listingData.buyerEmail;
    navigator.clipboard
      .writeText(shareEmail)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(() => {
        alert('Failed to copy the email. Please try manually.');
      });
  };

  useEffect(() => {
    if (contractAddress) {
      fetch(`${BACKEND_IP}/getListing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contractAddress }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setListingData(data);
          setLoading(false);
          console.log('Listing data:', data);

          // Update transaction and funds status based on listing data
          if (data.status !== 'Buyer Requested') {
            setIsTransactionConfirmed(true);
          }

          if (data.status === 'Completed') {
            setIsFundsReleased(true);
          }
        })
        .catch((error) => {
          console.error('Error fetching listing data:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [contractAddress]);

  // useEffect to handle pending actions after login
  useEffect(() => {
    if (isLoggedIn && pendingAction && sellerEmail) {
      if (pendingAction === 'acceptRequest') {
        proceedWithAcceptRequest();
      } else if (pendingAction === 'confirmSent') {
        proceedWithConfirmSent();
      }
      setPendingAction('');
    }
  }, [isLoggedIn, pendingAction, sellerEmail]);

  const handleAcceptRequest = async () => {
    if (isTransactionConfirmed) {
      return;
    }

    if (!isLoggedIn) {
      setPendingAction('acceptRequest');
      handleSignInClick();
      return;
    }
    await proceedWithAcceptRequest();
  };

  const proceedWithAcceptRequest = async () => {
    if (!sellerEmail) {
      console.log('Email not found.');
      alert('Email not found.');
      return;
    }

    let contractABI;
    try {
      const response = await fetch('/smartContracts/requestListingUSDC.json');
      if (!response.ok) {
        throw new Error('Failed to fetch ABI');
      }
      const artifact = await response.json();
      contractABI = artifact.abi;
    } catch (error) {
      console.error('Failed to load ABI:', error);
      setIsLoadingPopup(false);
      setPopupMessage('');
      return;
    }
    // Switch to the desired network
    let provider, signer;
    try {
      ({ provider, signer } = await switchNetwork(NETWORK_NAME));
    } catch (error) {
      alert(error.message);
      setIsLoadingPopup(false);
      setPopupMessage('');
      return;
    }

    try {
      const seller_address = await signer.getAddress();

      const contract = new Contract(contractAddress, contractABI, signer);

      const txResponse = await contract.setSellerAddress(seller_address);

      setIsLoadingPopup(true);
      setPopupMessage('Processing transaction...');

      await txResponse.wait();

      setPopupMessage('Transaction confirmed.');
      setIsTransactionConfirmed(true);

      fetch(`${BACKEND_IP}/bind-seller`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          seller_email: sellerEmail,
          contractAddress,
          seller_address,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          // Handle success
          console.log('Seller bound successfully:', data);
        })
        .catch((error) => {
          console.error('Error accepting request:', error);
        });
    } catch (error) {
      console.error('Transaction error:', error);
      setPopupMessage(
        'Error calling setSellerAddress(). See console for details.'
      );
    } finally {
      setIsLoadingPopup(false);
    }
  };

  const handleConfirmSent = async () => {
    if (isFundsReleased) {
      alert('Funds already released.');
      return;
    }

    // if (!isLoggedIn) {
    //   setPendingAction('confirmSent');
    //   handleSignInClick();
    //   return;
    // }

    await proceedWithConfirmSent();
  };

  const proceedWithConfirmSent = async () => {
    try {
      
      // Initialize Google API if not already loaded
      await loadGoogleApi();

      // Request authorization code with required scope
      const code = await getAuthorizationCode();
      console.log(code);

      // Show the popup with spinner
      setIsLoadingPopup(true);
      setPopupMessage('Verifying ticket delivery...');

      // Send the code and contractAddress to the backend
      const response = await fetch(`${BACKEND_IP}/verify_email_received`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, contractAddress }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("returned from frontene: ", data)
      setPopupMessage('Funds released successfully!');
      setIsFundsReleased(true);
    } catch (error) {
      console.error('Error confirming ticket sent:', error);
      setIsLoadingPopup(false);
      setPopupMessage(
        "Failed to confirm ticket sent. Please make sure you have sent it to the specified buyer's email address."
      );
    } finally {
      setIsLoadingPopup(false);
    }
  };

  const loadGoogleApi = () => {
    return new Promise((resolve, reject) => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          reject(new Error('Failed to load Google API'));
        };
        document.body.appendChild(script);
      } else {
        resolve();
      }
    });
  };

  const getAuthorizationCode = () => {
    return new Promise((resolve, reject) => {
      const { google } = window;
      if (!google) {
        console.error('Google API not loaded.');
        reject(new Error('Google API not loaded.'));
        return;
      }

      const codeClient = google.accounts.oauth2.initCodeClient({
        client_id: GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/gmail.readonly',
        ux_mode: 'popup',
        redirect_uri: 'postmessage',
        access_type: 'offline',
        prompt: 'consent',
        callback: (response) => {
          if (response.code) {
            resolve(response.code);
          } else {
            reject(new Error('Authorization failed'));
          }
        },
      });

      codeClient.requestCode();
    });
  };

  if (loading) {
    return <div className={styles.loadingContainer}>Loading...</div>;
  }

  if (!listingData) {
    return <div className={styles.loadingContainer}>Listing not found.</div>;
  }

  return (
    <div className={styles.AcceptRequest}>
      <Header />
      <div className={styles.ContentWrapper}>
        <div className={styles.PageProduct}>
          <div className={styles.Section}>
            <img
              className={styles.Image}
              src={
                listingData.eventImage ||
                'https://via.placeholder.com/546x383'
              }
              alt={listingData.eventName || 'Event'}
            />

            <div className={styles.Column}>
              <div className={styles.Body}>
                <div className={styles.Title}>
                  <div className={styles.TextHeading}>
                    You're selling a ticket for:
                  </div>
                  <div className={styles.EventName}>
                    {listingData.eventName || 'Event Name'}
                  </div>
                  <div className={styles.EventDate}>
                    {listingData.eventDate || 'Event Date'}
                  </div>
                  <div className={styles.Price}>
                    <div className={styles.PriceAmount}>
                      $ {listingData.price || 'Price'}
                    </div>
                  </div>
                </div>
                <div className={styles.Text}>
                  <strong>{listingData.buyerEmail || 'User'}</strong> is
                  requesting this ticket from you.
                </div>
              </div>
              <button
                className={styles.ActionButton}
                onClick={handleCopyEmail}
              >
                {copied ? (
                  <>
                    <FaCheckCircle color="#02542D" />
                    Email Copied!
                  </>
                ) : (
                  "Copy Buyer's Email"
                )}
              </button>
              <div className={styles.BuyerEmail}>
                {listingData.buyerEmail}
              </div>
              <div className={styles.Accordion}>
                <div className={styles.AccordionItem}>
                  <div className={styles.AccordionTitle}>
                    <div className={styles.Title}>Reminder</div>
                  </div>
                  <div className={styles.AccordionContent}>
                    <div className={styles.Body}>
                      Please send your ticket to the buyer's email address provided above. Once the buyer receives the ticket, you will receive your payment automatically.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.CardGridPricing}>
          <div className={styles.CardGrid}>
            <div className={styles.PricingCard}>
              <div className={styles.CardHeader}>
                <div className={styles.StepNumber}>Step 1</div>
                <div className={styles.StepTitle}>Accept the Request</div>
              </div>
              <div className={styles.TextList}>
                <div className={styles.TextListItem}>
                  Sign in with MetaMask.
                </div>
                <div className={styles.TextListItem}>
                  Accept the transaction to confirm you want to sell the ticket.
                </div>
              </div>
              <button
                className={styles.ActionButton}
                onClick={handleAcceptRequest}
                disabled={isTransactionConfirmed}
              >
                {isTransactionConfirmed ? (
                  <>
                    <FaCheckCircle color="#02542D" />
                    Transaction Confirmed
                  </>
                ) : (
                  'Accept Request'
                )}
              </button>
            </div>
            <div className={styles.PricingCard}>
              <div className={styles.CardHeader}>
                <div className={styles.StepNumber}>Step 2</div>
                <div className={styles.StepTitle}>Send the Ticket</div>
              </div>
              <div className={styles.TextList}>
                <div className={styles.TextListItem}>
                  Log into{' '}
                  <a
                    href="https://www.ticketmaster.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ticketmaster
                  </a>
                  .
                </div>
                <div className={styles.TextListItem}>
                  Send your ticket to the buyer's email address provided above.
                </div>
                <div className={styles.TextListItem}>
                  Return here and confirm to receive your payment!
                </div>
              </div>
              <button
                className={styles.ActionButton}
                onClick={handleConfirmSent}
                disabled={!isTransactionConfirmed || isFundsReleased}
              >
                {isFundsReleased ? (
                  <>
                    <FaCheckCircle color="#02542D" />
                    Funds Released
                  </>
                ) : (
                  'Confirm Sent'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Message */}
      {popupMessage && (
        <div className={styles.Popup}>
          <div className={styles.PopupContent}>
            {isLoadingPopup && <div className={styles.Spinner}></div>}
            <p>{popupMessage}</p>
            {!isLoadingPopup && (
              <button
                onClick={() => setPopupMessage('')}
                className={styles.CloseButton}
              >
                Close
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AcceptRequest;