// TermsAndConditions.jsx

import React from 'react';
import './TermsAndConditions.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom'; // For internal links

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p><strong>Revised:</strong> 11.23.2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Flipp Tickets, the premier peer-to-peer (P2P) platform for buying and selling event tickets. These terms and conditions govern your use of our services and website.
      </p>

      <h2>2. Definitions</h2>
      <p>
        "<strong>Flipp Tickets</strong>" refers to Flipp Tickets Corporation. "<strong>Marketplace</strong>" refers to Flipp Tickets Corporation's platform. "<strong>User</strong>" refers to anyone using the Marketplace to buy or sell tickets. "<strong>Tickets</strong>" refers to the event tickets listed for sale on the Marketplace. "<strong>Service</strong>" refers to the platform, website, and all services provided by Flipp Tickets Corporation for buying, selling, and managing event tickets.
      </p>

      <h2>3. General Conditions</h2>
      <p>
        By using our services, you agree to these terms and conditions in their entirety. Users must be 18 years or older, or have legal parental or guardian consent.
      </p>

      <h2>4. User Accounts</h2>
      <p>
        Users may be required to create an account to buy or sell tickets. Account information must be accurate and kept up-to-date. Users are responsible for maintaining the confidentiality of their account. Flipp Tickets is not responsible for any issues arising from inaccurate or outdated account information provided by users.
      </p>

      <h2>5. Peer-to-Peer (P2P) Transactions</h2>
      <p>
        Flipp Tickets facilitates direct transactions between buyers and sellers of event tickets. We provide a secure platform for users to list, discover, and purchase tickets. As a P2P platform, Flipp Tickets does not hold, store, or transfer tickets on behalf of users.
      </p>

      <h2>6. Payment Methods</h2>
      <p>
        Users can make payments through the following methods:
      </p>
      <ul>
        <li><strong>Base using USDC:</strong> Utilize the Base blockchain platform to make payments using USD Coin (USDC), a stablecoin pegged to the US Dollar.</li>
        <li><strong>Stripe:</strong> Process payments securely through Stripe, a trusted third-party payment processor.</li>
      </ul>

      <h2>7. Transaction Guarantee</h2>
      <p>
        Flipp Tickets guarantees a maximum transaction window of 24 hours between the payment and the receipt of the ticket. This ensures that buyers receive their tickets promptly after completing the payment.
      </p>

      <h2>8. User Responsibilities</h2>
      <p>
        <strong>Sellers:</strong> Must list tickets accurately and deliver them within the guaranteed transaction window. Failure to do so may result in penalties as outlined in our Seller Non-Delivery Policy.
      </p>
      <p>
        <strong>Buyers:</strong> Must complete payments promptly and provide accurate contact information to facilitate ticket delivery.
      </p>

      <h2>9. Return/Refund Policy</h2>
      <p>
        All sales are final. No compensation will be given for any reason other than cancellation of the event or failure to timely deliver valid tickets. Flipp Tickets will facilitate communication between buyer and seller in case of disputes. The timing of the refund may depend on several factors including the policies of the event organizers and third-party payment processors.
      </p>

      <h2>10. Seller Non-Delivery Policy</h2>
      <h3>Definition of Non-Delivery</h3>
      <p>
        Non-delivery occurs when a seller fails to deliver the purchased tickets to the buyer within the guaranteed 24-hour transaction window.
      </p>

      <h3>Consequences of Non-Delivery</h3>
      <p>
        If a seller fails to deliver tickets within 24 hours of payment, Flipp Tickets will:
      </p>
      <ul>
        <li>Issue a full refund to the buyer.</li>
      </ul>

      <h3>Dispute Resolution</h3>
      <p>
        In the event of non-delivery, buyers can escalate the issue to Flipp Tickets' customer support within 48 hours of the transaction. Flipp Tickets will mediate between the buyer and seller to resolve the dispute.
      </p>

      <h2>11. Liability</h2>
      <p>
        Flipp Tickets is not liable for any loss or damage arising from your use of the Marketplace, beyond the amount paid for tickets purchased.
      </p>

      <h2>12. User Conduct</h2>
      <p>
        Users must not use the Marketplace for any unlawful or fraudulent activity. Any such use will result in immediate termination of your account.
      </p>

      <h2>13. Termination</h2>
      <p>
        We reserve the right to terminate or suspend access to our Marketplace at any time, without prior notice or liability, for any reason.
      </p>

      <h2>14. Governing Law</h2>
      <p>
        These terms and conditions are governed by the laws of New York.
      </p>

      <h2>15. Amendments</h2>
      <p>
        We reserve the right to update or change our terms and conditions at any time. Continued use of the service after such changes constitutes acceptance of the new terms.
      </p>

      <h2>16. Privacy Policy</h2>
      <p>
        We are committed to protecting your personal information. Our <Link to="/privacy-policy">Privacy Policy</Link> outlines how we collect, use, and secure your data.
      </p>

      <h2>17. Intellectual Property</h2>
      <p>
        All content on the Marketplace, including text, graphics, logos, and images, is the property of Flipp Tickets and is protected by copyright laws.
      </p>

      <h2>18. Copyright</h2>
      <p>
        Flipp Tickets Services, including the Flipp Tickets website, are owned and operated by Flipp Tickets. All content included in or made available through any Flipp Tickets Services, such as text, visual interfaces, graphics, design, logos, icons, images, digital downloads, computer code (including source code or object code), data compilations, software, and all other elements of the Services is the exclusive property of Flipp Tickets and protected by United States and international copyright laws.
      </p>

      <h2>19. Contact Information</h2>
      <p>
        For any questions or concerns about these terms, please contact us at:
      </p>
      <ul>
        <li><strong>Email:</strong> <a href="mailto:admin@flipptickets.com">admin@flipptickets.com</a></li>
        <li><strong>Address:</strong> 517 w 113th St, New York, NY</li>
        <li><strong>Phone:</strong> 347-3270-324</li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
