// src/components/AccordionItem.js

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionItem.module.css';
import { FaChevronDown } from 'react-icons/fa';

function AccordionItem({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <div className={styles.accordionItem}>
      <button
        className={styles.accordionButton}
        onClick={toggleAccordion}
        aria-expanded={isOpen}
        aria-controls={`accordion-content-${title}`}
        id={`accordion-header-${title}`}
      >
        <span>{title}</span>
        <FaChevronDown
          className={`${styles.icon} ${isOpen ? styles.rotate : ''}`}
        />
      </button>
      <div
        id={`accordion-content-${title}`}
        role="region"
        aria-labelledby={`accordion-header-${title}`}
        className={styles.accordionContent}
        ref={contentRef}
      >
        <p dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br />') }} />
      </div>
    </div>
  );
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default AccordionItem;