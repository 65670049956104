// src/components/RequestATicket.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseEther, parseUnits, Contract, ContractFactory } from 'ethers';
import { useGoogleAuth } from './GoogleAuthContext';
import { switchNetwork } from './utils/switchNetwork';
import './RequestATicket.css';
import axios from 'axios';

const BACKEND_IP = process.env.REACT_APP_BACKEND_IP;
const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME; // Use environment variable or default
const MASTER_ADDRESS = process.env.REACT_APP_MASTER_ADDRESS; // Master wallet address from env
const USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS; // USDC contract address from env

console.log("Network:", NETWORK_NAME);
console.log("Master Address:", MASTER_ADDRESS);
console.log("USDC Address:", USDC_ADDRESS);

function RequestATicket() {
  const navigate = useNavigate();
  const { isLoggedIn, userEmail } = useGoogleAuth();

  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [price, setPrice] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // State variables for event suggestions
  const [suggestions, setSuggestions] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const [seatMapUrl, setSeatMapUrl] = useState('');

  useEffect(() => {
    if (userEmail) {
      setBuyerEmail(userEmail);
    }
  }, [userEmail]);

  // Handle event name input change
  const handleEventNameChange = (e) => {
    const value = e.target.value;
    setEventName(value);

    // Clear the selected event and date if the eventName changes
    setSelectedEvent(null);
    setEventDate('');

    // Check if the last character indicates end of a word
    const lastChar = value.slice(-1);
    if (/\s/.test(lastChar)) {
      // User typed a space or punctuation, indicating end of a word
      fetchEventSuggestions(value.trim());
    } else {
      // Debounce API calls to prevent too many requests
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          fetchEventSuggestions(value.trim());
        }, 1000) // Wait 1 second after the user stops typing
      );
    }
  };

  // Fetch event suggestions from the backend
  const fetchEventSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await axios.get(`${BACKEND_IP}/api/search-events`, {
        params: { eventName: query },
      });

      const events = response.data.events || [];
      setSuggestions(events);
    } catch (error) {
      console.error('Error fetching event suggestions:', error);
    }
  };

  // Handle selecting an event from suggestions
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setEventName(event.name);
    setEventDate(event.date);
    setSeatMapUrl(event.seatmap); // Set the seat map URL
    setSuggestions([]);
  };

  // Function to convert USD to Wei
  async function convertUsdToWei(priceInDollars) {
    let amountInWei;

    try {
      // Validate the input price
      const usd = parseFloat(priceInDollars);
      if (isNaN(usd) || usd <= 0) {
        throw new Error('Invalid price. Please enter a positive number.');
      }

      // Fetch the current ETH/USD price from CoinGecko
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price',
        {
          params: {
            ids: 'ethereum',
            vs_currencies: 'usd',
          },
        }
      );

      const ethPriceInUsd = response.data.ethereum.usd;

      if (!ethPriceInUsd) {
        throw new Error('Failed to retrieve ETH price.');
      }

      // Convert USD to Ether
      const priceInEth = (usd / ethPriceInUsd).toFixed(18); // Ensuring precision up to 18 decimals

      // Convert Ether to Wei
      amountInWei = parseEther(priceInEth);
    } catch (error) {
      alert(error.message);
      return null;
    }

    return amountInWei;
  }

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const paymentMethod = e.nativeEvent.submitter.value; // Get the value of the button that was clicked

    if (!paymentMethod) {
      alert('Please select a payment method.');
      return;
    }

    if (!selectedEvent) {
      alert('Please select a valid event from the suggestions.');
      return;
    }

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateCreated = new Date().toLocaleDateString('en-US', dateOptions);

    // Create a ticket request object
    const ticketRequest = {
      eventName: selectedEvent.name,
      eventId: selectedEvent.id,
      eventDate: selectedEvent.date, // Include event date
      eventImage: selectedEvent.image, // Include event image
      price,
      dateCreated,
      buyerEmail,
    };

    // Validate input fields
    if (!eventName || !buyerEmail || !price) {
      alert('Please fill in all required fields.');
      return;
    }

    // Switch to the desired network
    let provider, signer;
    try {
      ({ provider, signer } = await switchNetwork(NETWORK_NAME));
    } catch (error) {
      alert(error.message);
      return;
    }

    // Variables to hold contract address and transaction hash
    let contractAddress, transactionHash;

    try {
      if (paymentMethod === 'ETH') {
        // ETH payment flow
        // Convert USD price to Wei
        let amountInWei;
        try {
          amountInWei = await convertUsdToWei(price);
          if (!amountInWei) {
            return;
          }
        } catch (error) {
          alert('Invalid price. Please enter a valid number.');
          return;
        }

        // Fetch the ABI and Bytecode for ETH contract
        let contractABI, contractBytecode;
        try {
          const response = await fetch('/smartContracts/requestListing.json');
          if (!response.ok) {
            throw new Error('Failed to fetch contract data.');
          }
          const artifact = await response.json();
          contractABI = artifact.abi;
          contractBytecode = artifact.bytecode;

          // Handle bytecode format
          if (typeof contractBytecode !== 'string') {
            contractBytecode = contractBytecode.object;
          }

          if (!contractBytecode.startsWith('0x')) {
            contractBytecode = '0x' + contractBytecode;
          }
        } catch (error) {
          console.error('Failed to load ABI and Bytecode:', error);
          alert('Error loading contract data.');
          return;
        }

        // Set manual gas limit (adjust as necessary)
        const gasLimit = 5000000;

        // Deploy the contract
        const constructorArgs = [eventName, amountInWei];
        const options = {
          value: amountInWei, // Send Ether during deployment
          gasLimit: gasLimit,
        };

        const factory = new ContractFactory(contractABI, contractBytecode, signer);
        const contract = await factory.deploy(...constructorArgs, options);
        // Set loading state and provide feedback to the user via popup
        setIsLoading(true);
        setPopupMessage('Deployment in progress...');
        await contract.waitForDeployment();

        // Get the contract address and transaction hash
        contractAddress = contract.target;
        const deploymentTx = await contract.deploymentTransaction();
        transactionHash = deploymentTx.hash;

        // Update popup message
        setPopupMessage(`Contract deployed at address: ${contractAddress}`);

        // Include the contract address in the ticketRequest object
        ticketRequest.contractAddress = contractAddress;
      } else if (paymentMethod === 'USDC') {
        // USDC payment flow
        // Convert USD price to USDC (6 decimals)
        let amountInUSDC;
        try {
          const usd = parseFloat(price);
          if (isNaN(usd) || usd <= 0) {
            throw new Error('Invalid price. Please enter a positive number.');
          }
          amountInUSDC = parseUnits(price, 6); // USDC has 6 decimals
        } catch (error) {
          alert('Invalid price. Please enter a valid number.');
          return;
        }

        // Fetch the ABI and Bytecode for USDC contract
        let contractABI, contractBytecode;
        try {
          const response = await fetch('/smartContracts/requestListingUSDC.json');
          if (!response.ok) {
            throw new Error('Failed to fetch contract data.');
          }
          const artifact = await response.json();
          contractABI = artifact.abi;
          contractBytecode = artifact.bytecode;

          // Handle bytecode format
          if (typeof contractBytecode !== 'string') {
            contractBytecode = contractBytecode.object;
          }

          if (!contractBytecode.startsWith('0x')) {
            contractBytecode = '0x' + contractBytecode;
          }
        } catch (error) {
          console.error('Failed to load ABI and Bytecode:', error);
          alert('Error loading contract data.');
          return;
        }

        // Set manual gas limit (adjust as necessary)
        const gasLimit = 5000000;

        if (!USDC_ADDRESS) {
          throw new Error('USDC contract address not set in environment variables.');
        }

        if (!MASTER_ADDRESS) {
          throw new Error('Master address not set in environment variables.');
        }

        // Prepare the constructor arguments
        const constructorArgs = [
          eventName,
          amountInUSDC,
          USDC_ADDRESS,
          MASTER_ADDRESS, // Set the master address from environment variable
        ];
        const options = {
          gasLimit: gasLimit,
        };

        // Create the ContractFactory and deploy the contract
        const factory = new ContractFactory(contractABI, contractBytecode, signer);
        const contract = await factory.deploy(...constructorArgs, options);

        setIsLoading(true);
        setPopupMessage('Deployment in progress...');

        // Wait for the contract deployment to be mined
        await contract.waitForDeployment();

        // Get the contract address and transaction hash using Ethers.js v6.x
        contractAddress = contract.target; // Use 'target' instead of 'address' in Ethers.js v6
        transactionHash = contract.deploymentTransactionHash; // Access the hash directly

        // Step 1: Approve USDC to be spent by the deployed contract
        const usdcContract = new Contract(
          USDC_ADDRESS,
          [
            // Minimal ABI for approve
            "function approve(address spender, uint256 amount) public returns (bool)",
          ],
          signer // Buyer is the signer
        );

        setPopupMessage('Approving USDC transfer...');
        const approveTx = await usdcContract.approve(contractAddress, amountInUSDC);
        await approveTx.wait();

        // Step 2: Call depositFunds on the deployed contract
        const requestListingContract = new Contract(
          contractAddress,
          contractABI, // Ensure ABI includes depositFunds function
          signer // Buyer is the signer
        );

        setPopupMessage('Depositing USDC into the contract...');
        const depositTx = await requestListingContract.depositFunds();
        await depositTx.wait();

        // Update popup message upon success
        setPopupMessage(`USDC deposited successfully. Contract deployed at address: ${contractAddress}`);

        // Include the contract address in the ticketRequest object
        ticketRequest.contractAddress = contractAddress;
      }

      // Send the ticketRequest object to the backend
      const serverResponse = await fetch(`${BACKEND_IP}/create_listing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies if necessary
        body: JSON.stringify({ ticketRequest }),
      });

      if (serverResponse.ok) {
        const data = await serverResponse.json();
        console.log('Server response:', data);

        // Navigate to LinkScreen with actual data
        navigate('/linkscreen', {
          state: {
            contractAddress: contractAddress,
            transactionHash: transactionHash,
          },
        });
      } else {
        const errorData = await serverResponse.json();
        console.error('Server error:', errorData);
        alert('Error submitting ticket request to the server.');
      }
    } catch (error) {
      console.error('Deployment error:', error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        alert('Insufficient funds for gas and transaction value.');
      } else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        alert('Transaction may fail or require manual gas limit.');
      } else {
        alert('Error deploying contract: ' + error.message);
      }
    } finally {
      // Stop loading spinner after the process ends
      setIsLoading(false);
    }
  };

  return (
    <div className="RequestATicket">
      {/* Title */}
      <h1 className="RequestATicketTitle">Request a Ticket</h1>

      {/* Individual Listing Section */}
      <div className="IndividualListing">
        {/* Form Contact Section */}
        <form onSubmit={handleSubmit} className="FormContact">
          {/* Event Name Input Field */}
          <div className="InputField">
            <label htmlFor="eventName" className="InputLabel">
              Event Name
            </label>
            <div className="EventInputContainer">
              <input
                type="text"
                id="eventName"
                name="eventName"
                value={eventName}
                onChange={handleEventNameChange}
                className="InputFieldLarge"
                placeholder="Enter the event name"
                required
              />
              {suggestions.length > 0 && (
                <ul className="SuggestionsList">
                  {suggestions.map((event) => (
                    <li
                      key={event.id}
                      onClick={() => handleSelectEvent(event)}
                      className="SuggestionItem"
                    >
                      {event.name} - {event.date}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Event Date Field */}
          <div className="InputField">
            <label htmlFor="eventDate" className="InputLabel">
              Event Date
            </label>
            <input
              type="text"
              id="eventDate"
              name="eventDate"
              value={eventDate}
              readOnly // Make the field read-only
              className="InputFieldLarge ReadOnlyField"
              placeholder="Will be filled automatically (YYYY-MM-DD)"
              required
            />
          </div>

          {/* Display Event Image */}
          {selectedEvent && selectedEvent.image && (
            <div className="EventImageContainer">
              <img
                src={selectedEvent.image}
                alt={selectedEvent.name}
                className="EventImage"
              />
            </div>
          )}

          {/* Price Field */}
          <div className="InputField">
            <label htmlFor="price" className="InputLabel">
              Price (in $)
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="InputFieldLarge"
              placeholder="Enter the price"
              required
            />
          </div>

          {/* Buyer's Email Field (Read-Only) */}
          <div className="InputField">
            <label htmlFor="buyerEmail" className="InputLabel">
              Your Email
            </label>
            <input
              type="email"
              id="buyerEmail"
              name="buyerEmail"
              value={buyerEmail}
              readOnly // Make the field read-only
              className="InputFieldLarge ReadOnlyField"
              placeholder="Your email is pre-filled"
              required
            />
          </div>

          {/* Submit Buttons */}
          <div className="ButtonGroup-request-ticket">
            <button type="submit" name="paymentMethod" value="ETH" className="Button-request-ticket">
              Pay with ETH
            </button>
            <button type="submit" name="paymentMethod" value="USDC" className="Button-request-ticket">
              Pay with USDC
            </button>
          </div>
        </form>
      </div>

      {/* Popup Message */}
      {popupMessage && (
        <div className="Popup">
          <div className="PopupContent">
            {isLoading && <div className="Spinner"></div>}
            <p>{popupMessage}</p>
            {!isLoading && (
              <button onClick={() => setPopupMessage('')} className="CloseButton">
                Close
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestATicket;
