// src/components/ScrollToTop.jsx

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * ScrollToTop Component
 * 
 * This component listens for route changes and scrolls the window to the top
 * whenever the location changes.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the window when the pathname changes
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', // Optional: adds a smooth scrolling effect
    });
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTop;
