// Footer.jsx

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import './Footer.css'; // Import the CSS file
import { FaInstagram, FaLinkedin } from 'react-icons/fa'; // Import icons from react-icons

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Company Section */}
        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li><Link to="/about-us" className="footer-link">About</Link></li>
            <li><Link to="/faq" className="footer-link">FAQ</Link></li>

          </ul>
        </div>

        {/* Resources Section */}
        <div className="footer-section">
          <h4>Resources</h4>
          <ul>
            <li><Link to="/privacy-policy" className="footer-link">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions" className="footer-link">Terms and Conditions</Link></li>
          </ul>
        </div>

        {/* Connect Section */}
        <div className="footer-section">
          <h4>Connect</h4>
          <ul>
            <li>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="footer-link">
                <FaInstagram className="footer-icon" /> Instagram
              </a>
            </li>
            <li>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="footer-link">
                <FaLinkedin className="footer-icon" /> LinkedIn
              </a>
            </li>
            <li><Link to="/help" className="footer-link">Help</Link></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Flipp Tickets. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
