// YourTransactions.js

import React, { useState, useEffect } from 'react';
import Header from './Header';
import { useGoogleAuth } from './GoogleAuthContext';
import './YourTransactions.css';

const BACKEND_IP = process.env.REACT_APP_BACKEND_IP;

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

function YourTransactions() {
  const { userEmail } = useGoogleAuth();
  const [buyerListings, setBuyerListings] = useState([]);
  const [sellerListings, setSellerListings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [activeTab, setActiveTab] = useState('Buying');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    fetch(`${BACKEND_IP}/get-all-listings`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userEmail }),
    })
      .then((response) => response.json())
      .then((data) => {
        const buyer = data.filter((listing) => listing.buyerEmail === userEmail);
        const seller = data.filter((listing) => listing.sellerEmail === userEmail);
        setBuyerListings(buyer);
        setSellerListings(seller);
      })
      .catch((error) => console.error('Error fetching listings:', error));
  }, [userEmail]);

  const handleListingClick = (listing) => {
    setSelectedListing(listing);
    setIsModalOpen(true);
    setCopied(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedListing(null);
  };

  const activeListings = activeTab === 'Buying' ? buyerListings : sellerListings;

  // Function to parse 'dateCreated' into a Date object
  const parseDate = (dateString) => {
    // dateString format: 'November 21, 2024'
    return new Date(dateString);
  };

  // Sort listings by 'dateCreated' in descending order (most recent first)
  const sortedActiveListings = [...activeListings].sort((a, b) => {
    const dateA = parseDate(a.dateCreated);
    const dateB = parseDate(b.dateCreated);
    return dateB - dateA;
  });

  const inProgressListings = sortedActiveListings.filter(
    (listing) => listing.status !== 'Completed'
  );

  const completedListings = sortedActiveListings.filter(
    (listing) => listing.status === 'Completed'
  );

  const shareLink = (contractAddress) => {
    return `${window.location.origin}/acceptrequest?contractAddress=${contractAddress}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink(selectedListing.contractAddress));
    setCopied(true);
  };

  return (
    <div className="YourTransactions">
      <Header />

      <h1 className="your-transactions-title">Your Transactions</h1>

      {/* Rounded Tabs */}
      <div className="tabs">
        <div
          className={`tab ${activeTab === 'Buying' ? 'active' : ''}`}
          onClick={() => setActiveTab('Buying')}
        >
          Buying
        </div>
        <div
          className={`tab ${activeTab === 'Selling' ? 'active' : ''}`}
          onClick={() => setActiveTab('Selling')}
        >
          Selling
        </div>
      </div>

      {/* Listings Grid */}
      <div className="listings-container">
        {/* In Progress Listings */}
        <div className="section">
          <h2 className="section-title">In Progress</h2>
          {inProgressListings.length > 0 ? (
            <div className="card-grid">
              {inProgressListings.map((listing) => {
                // Determine if the listing is in the 'Selling' tab and not completed
                const isSellingActive =
                  activeTab === 'Selling' && listing.status !== 'Completed';

                const cardContent = (
                  <>
                    <img
                      src={listing.eventImage || 'https://via.placeholder.com/300x200'}
                      alt={listing.eventName || 'Event Image'}
                      className="listing-image"
                    />
                    <h3 className="listing-event-name">
                      {listing.eventName || 'Event Name'}
                    </h3>
                    {/* Display the event date */}
                    <p className="listing-event-date">
                      {listing.eventDate ? `${listing.eventDate}` : 'Date: N/A'}
                    </p>
                    <div className="listing-details">
                      <span className="listing-price">
                        ${listing.price || '0.00'}
                      </span>
                      <span
                        className={`listing-status ${
                          listing.status === 'Completed' ? 'completed' : ''
                        }`}
                      >
                        {listing.status || 'N/A'}
                      </span>
                    </div>
                  </>
                );

                if (isSellingActive) {
                  // Wrap the card in an <a> tag
                  return (
                    <a
                      key={listing._id}
                      href={shareLink(listing.contractAddress)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="listing-card-link"
                    >
                      <div className="listing-card">{cardContent}</div>
                    </a>
                  );
                } else if (listing.status !== 'Completed') {
                  // For other cases (e.g., Buying tab), keep the existing onClick behavior
                  return (
                    <div
                      key={listing._id}
                      className="listing-card"
                      onClick={() => handleListingClick(listing)}
                    >
                      {cardContent}
                    </div>
                  );
                } else {
                  // For completed listings, do not add onClick handler
                  return (
                    <div key={listing._id} className="listing-card">
                      {cardContent}
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <p className="no-listings">No in-progress listings.</p>
          )}
        </div>

        {/* Completed Listings */}
        <div className="section">
          <h2 className="section-title">Completed</h2>
          {completedListings.length > 0 ? (
            <div className="card-grid">
              {completedListings.map((listing) => {
                const cardContent = (
                  <>
                    <img
                      src={listing.eventImage || 'https://via.placeholder.com/300x200'}
                      alt={listing.eventName || 'Event Image'}
                      className="listing-image"
                    />
                    <h3 className="listing-event-name">
                      {listing.eventName || 'Event Name'}
                    </h3>
                    {/* Display the event date */}
                    <p className="listing-event-date">
                      {listing.eventDate ? `Date: ${listing.eventDate}` : 'Date: N/A'}
                    </p>
                    <div className="listing-details">
                      <span className="listing-price">
                        ${listing.price || '0.00'}
                      </span>
                      <span className="listing-status completed">
                        {listing.status || 'N/A'}
                      </span>
                    </div>
                  </>
                );

                // For completed listings, do not add onClick handler
                return (
                  <div key={listing._id} className="listing-card">
                    {cardContent}
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="no-listings">No completed listings.</p>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {selectedListing && (
          <div className="modal-body">
            <h2 className="modal-title">{selectedListing.eventName}</h2>
            {/* Display event date in modal */}
            <p className="modal-event-date">
              {selectedListing.eventDate
                ? `Date: ${selectedListing.eventDate}`
                : 'Date: N/A'}
            </p>
            <p className="modal-text">Share this link:</p>
            <a
              href={shareLink(selectedListing.contractAddress)}
              className="modal-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {shareLink(selectedListing.contractAddress)}
            </a>
            <button className="modal-copy-button" onClick={handleCopy}>
              {copied ? 'Copied' : 'Copy Link'}
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default YourTransactions;
