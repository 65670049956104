// src/utils/switchNetwork.js

import { networks } from './networks';
import { BrowserProvider } from 'ethers';

export async function switchNetwork(networkName) {
  const network = networks[networkName];
  if (!network) {
    throw new Error(`Network ${networkName} is not supported`);
  }

  if (typeof window.ethereum === 'undefined') {
    throw new Error('MetaMask is not installed. Please install MetaMask and try again.');
  }

  const provider = new BrowserProvider(window.ethereum);
  const currentNetwork = await provider.getNetwork();
  const currentChainId = '0x' + currentNetwork.chainId.toString(16);

  if (currentChainId.toLowerCase() !== network.chainId.toLowerCase()) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.chainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [network],
          });
        } catch (addError) {
          throw new Error('Failed to add network: ' + addError.message);
        }
      } else {
        throw new Error('Failed to switch network: ' + switchError.message);
      }
    }
  }

  // Re-initialize provider and signer after switching networks
  const newProvider = new BrowserProvider(window.ethereum);
  const signer = await newProvider.getSigner();

  return { provider: newProvider, signer };
}